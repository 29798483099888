import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { css } from "@emotion/core"
import Layout from "@app-components/layout/"

export default function Template({
  data // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds our post data
  const { frontmatter, html } = markdownRemark
  return (
    <Layout page={frontmatter.path.split("/").pop()} getStartedButtons={["get-started"]}>
      <section
        className="mb4"
        css={css`
          h2,
          h3,
          h4 {
            margin-top: 2rem;
          }
          ul,
          ol {
            margin-bottom: 1rem;
          }
        `}>
        <div
          css={css`
            background: linear-gradient(141.51deg, #fd2a51 0%, #ff4b6d 100%);
            padding-top: 8rem;
          `}>
          <div className="max-width-5 mx-auto pt4 pb3 px-2 md-px3 white">
            <h1 className="mt4">{frontmatter.title}</h1>
            <strong className="caption">Last Updated: {frontmatter.date}</strong>
          </div>
        </div>
        <div className="mt3 max-width-5 mx-auto px-2 md-px3" dangerouslySetInnerHTML={{ __html: html }} />
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
      }
    }
  }
`

Template.propTypes = {
  data: PropTypes.object.isRequired
}
